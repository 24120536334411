.content-wrapper {
	.scene-element {
		transition-timing-function: $standard;
		animation-duration: 200ms;
		animation-fill-mode: both;

		&--fade-in {
			animation-name: fade-in;
		}

		&--fade-in-up {
			animation-name: fade-in-up;
		}

		&--fade-in-down {
			animation-name: fade-in-down;
		}

		&--fade-in-right {
			animation-name: fade-in-right;
		}
	}

	// Reverse "exit" animations
	&.is-exiting {
		.scene-element {
			animation-direction: alternate-reverse;
			animation-delay: 0ms !important;
		}
	}
}

@keyframes fade-in {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}


@keyframes fade-in-up {
	0% {
		transform: translateY(30px);
		opacity: 0;
	}

	100% {
		transform: translateY(0);
		opacity: 1;
	}
}

@keyframes fade-in-down {
	0% {
		transform: translateY(-30px);
		opacity: 0;
	}

	100% {
		transform: translateY(0);
		opacity: 1;
	}
}

@keyframes fade-in-right {
	0% {
		transform: translateX(-30px);
		opacity: 0;
	}

	100% {
		transform: translateX(0);
		opacity: 1;
	}
}
