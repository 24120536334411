.digest {
    &__header {
        width: 100%;
        border-top: 2px solid $yellow;
        background-color: $blue;

        &__inner {
            display: flex;
            box-sizing: border-box;
            width: $max-width;
            max-width: 100%;
            margin: 0 auto;
            font-size: 0.8rem;
            align-items: center;
            justify-content: space-between;
        }

        &__cat {
            label { color: white; }

            select {
                padding: 0.4em 0.6em;
                border: none;
                margin-left: 1em;
            }

            @include media($on-mobile) { display: none; }
        }

        &__nav {
            display: flex;
        }

        &__link {
            display: block;
            padding: 1em;
            margin-right: 1em;
            color: white;
            text-decoration: none;

            &:hover {
                background-color: lighten($blue, 10%);
            }
        }
    }

    &__posts {
        display: flex;
        justify-content: space-between;
        width: $max-width;
        max-width: 100%;
        margin: 0 auto;
        flex-wrap: wrap;

        @include media($on-tablet) {
            box-sizing: border-box;
            padding: 0 1em !important;
        }

        @include media($on-mobile) {
            display: block;
        }

        &__control {
            &s {
                display: flex;
                width: 100%;
                justify-content: center;
                align-items: center;
                padding: 1em 0;
            }

            font-size: 0.8rem;

            @include link($blue);

            &__arrow {
                position: absolute;
                top: 0.1em;
                height: 1em;
                fill: $blue;
                transition: transform 200ms $standard;

                &--right { right: -1.4em; }

                &--left { left: -1.4em; }
            }

            &:hover:not(.digest__posts__control--disabled) .digest__posts__control__arrow--right { transform: translateX(1em); }

            &:hover:not(.digest__posts__control--disabled) .digest__posts__control__arrow--left { transform: translateX(-1em); }

            &--disabled {
                opacity: 0.6;
                cursor: default;
            }

            &:first-child { margin-right: 1em; }
        }
    }

    &__home {
        &__featured {
            display: flex;
            width: 100%;
            max-width: $max-width;
            margin: 0 auto;

            &__post {
                padding: 3em 0;
                border-bottom: 1px solid lighten($grey, 20%);

                &:first-of-type {
                    padding-top: 0;
                }

                &:last-of-type {
                    border-bottom: none;
                }

                &__title {
                    display: block;
                    color: black;
                    font-family: $fancy-stack;
                    font-size: 1.1rem;
                    font-weight: 700;
                    text-decoration: none;

                    &:hover { color: $blue; }
                }

                &__date {
                    font-size: 0.6rem;
                }
            }
        }

        &__title {
            margin: 1em 0;
            color: $blue;
            font-family: $fancy-stack;
        }

        &__post {
            position: relative;
            display: flex;
            box-sizing: border-box;
            width: calc(50% - 1em);
            padding: 3em 3em 3em 0;
            border-bottom: 1px solid lighten($grey, 20%);
            margin-top: 2em;
            flex-direction: column;
            font-size: 0.8rem;
            line-height: 1.4;

            &:nth-child(2n) {
                padding-right: 0;
                padding-left: 3em;
            }

            @include media($on-mobile) {
                width: 100%;
                padding: 0 !important;
            }

            &:nth-child(2n):not(:first-child)::before {
                content: '';
                position: absolute;
                top: 0;
                left: -1em;
                width: 1px;
                height: calc(100% - 2em);
                background-color: lighten($grey, 20%);

                @include media($on-mobile) {
                    display: none;
                }
            }

            &--newest {
                width: auto;
                padding: 0;
                margin-top: 0;
                margin-right: 2em;
                font-size: 0.9rem;
                line-height: 1.6;
                max-width: $max-width - 360px;

                .digest__home__post__cat {
                    &-list {
                        margin-top: 2em;
                        margin-bottom: 2em;
                    }
                }

                .digest__home__post__content {
                    height: auto;
                }

                @include media($on-tablet) {
                    box-sizing: border-box;
                    padding: 0 1em !important;
                }

                @include media($on-mobile) {
                    width: 100%;
                    max-width: none;
                    margin: 0;
                }
            }

            &__image {
                display: block;
                width: 100%;
                margin-bottom: 3em;

                img {
                    position: relative;
                    display: block;
                    width: 100%;
                    min-height: 100px;
                }

                img::before {
                    content: '';
                    position: absolute;
                    top: -10px;
                    left: 0;
                    display: block;
                    width: 100%;
                    height: calc(100% + 15px);
                    border: 2px dotted rgb(200, 200, 200);
                    border-radius: 5px;
                    background-color: rgb(230, 230, 230);
                }

                img::after {
                    content: 'Broken Image - ' attr(alt);
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    display: block;
                    width: 80%;
                    color: rgb(100, 100, 100);
                    font-size: 0.8rem;
                    text-align: center;
                    transform: translate(-50%, -50%);
                    white-space: pre-wrap;
                }
            }

            &__content {
                display: flex;
                width: 100%;
                height: 100%;
                align-items: flex-start;
                justify-content: space-between;
                flex-direction: column;
                line-height: inherit;

                img {
                    display: block;
                    margin: 0.4em 0;
                    max-width: 100%;
                }

                &-inner {
                    max-width: 100%;
                }
            }

            &__title {
                display: inline;
                box-sizing: border-box;
                padding: 0 0.2em;
                border-bottom: 2px solid transparent;
                margin-left: -0.2em;
                color: $blue;
                font-family: $fancy-stack;
                font-size: 1.4rem;
                font-weight: 700;
                line-height: 1.2;
                text-decoration: none;
                box-decoration-break: clone;
                transition: border-color 200ms $standard;

                &:hover {
                    border-color: $yellow;
                }
            }

            &__date {
                display: block;
                margin-top: 0.4em;
                margin-bottom: 2em;

                + * { margin-top: 0; }
            }

            &__continue {
                display: inline-block;
                padding: 0.2em 0;
                border-bottom: 1px dotted rgba($blue, 0.2);
                margin-top: 1em;
                color: $blue;
                white-space: pre;
                text-decoration: none;

                &:hover {
                    border-bottom-color: $blue;
                }
            }

            &__cat {
                &-list {
                    padding-top: 2em;
                    margin-top: auto;
                }

                @include accent-type($red);
                display: inline-block;
                margin-left: 1em;
                font-size: 0.6rem;
                font-weight: 700;
                text-decoration: none;

                &::before {
                    content: '•';
                    margin-right: 1em;
                }

                &:first-of-type {
                    margin-left: 0;

                    &::before {
                        display: none;
                    }
                }
            }
        }
    }

    &__aside {
        @include media($on-mobile) {
            display: none;
        }
    }
}
