.footer {
    width: 100%;
    padding: 5em 0 3em;
    border-top: 3px solid $yellow;
    flex: none;
    background-color: $dark-blue;
    color: lighten($soft-blue, 20%);
    font-size: 0.8rem;

    @include media($on-tablet) {
        box-sizing: border-box;
        padding: 2em;
    }

    &__inner {
        @include defWidth;
    }

    &__title {
        max-width: 200px;
        margin-top: 0;
        color: $yellow;
        font-weight: 400;
        font-size: 1rem;
    }

    &__sponsor {
        color: lighten($soft-blue, 30%);

        &:hover { color: white; }

        &-list {
            column-count: 2;
            padding: 0;
            max-width: 400px;
            list-style-type: none;

            li { margin-bottom: 0.2em; }
        }


    }

    &__address p {
        margin-top: 0;

        @include media($on-mobile) {
            margin-top: 2em;
        }
    }

    &__copyright {
        display: block;
    }

    &__contact-info {
        margin-top: 3em;

        &__title {
            display: block;
            margin-bottom: 1em;
            color: $yellow;
        }

        a {
            color: lighten($soft-blue, 30%);

            &:hover { color: white; }
        }
    }
}
