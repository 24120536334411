$on-mobile: 768px;
$on-tablet: 1024px;
$on-laptop: 1500px;

@mixin media($max-width) {
    @media screen and (max-width: $max-width) {
        @content;
    }
}


@mixin defWidth {
    width: 100%;
    max-width: $max-width;
    margin-right: auto;
    margin-left: auto;
}

@mixin link($color) {
    position: relative;
    color: $color;
    text-decoration: none;

    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: rgba($color, 0.6);
        transition: background-color 150ms $standard;
    }

    &:hover::after {
        background-color: $color;
    }
}

@mixin accent-type($color) {
    color: $color;
    font-family: $body-stack;
    font-size: 0.8rem;
    text-transform: uppercase;
    letter-spacing: 0.2em;
}

@mixin underline($color, $secondaryColor: $color, $backgroundColor: white) {
    color: $color;
    text-decoration: none;
    text-shadow: 1px 1px 0 $backgroundColor, -1px 1px 0 $backgroundColor, 2px 0 0 $backgroundColor, -2px 0 0 $backgroundColor;
    box-shadow: inset 0 -1px 0 0 $backgroundColor, inset 0 -3px 0 0 rgba($secondaryColor, 0.55);

    &:hover {
        box-shadow: inset 0 -1px 0 0 $backgroundColor, inset 0 -3px 0 0 $secondaryColor;
    }
}

@mixin triangle($direction, $size, $color) {
    width: 0;
    height: 0;
    border-width: $size;
    border-style: solid;
    border-color: transparent;

    @if $direction == 'top' {
        border-bottom-color: $color;
    } @else if $direction == 'bottom' {
        border-top-color: $color;
    } @else if $direction == 'right' {
        border-left-color: $color;
    } @else if $direction == 'left' {
        border-right-color: $color;
    }
}
