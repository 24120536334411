.home__section--featured {
    position: relative;
    padding: 4em 0 100px;

    .angle {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        #angle-image { background-color: $blue; }
    }

    @include media($on-mobile) {
        padding-bottom: 200px;
    }
}

.featured {
    &__inner {
        position: relative;
        z-index: 2;
        top: 90px;
        margin-bottom: 100px;

        @include defWidth;

        @include media($on-tablet) {
            box-sizing: border-box;
            padding: 0 1em;
        }
    }

    &__issue {
        color: white;
        font-size: 0.8em;
        max-width: 600px;
        animation-delay: 150ms;

        p { max-width: 300px; }

        a {

            @include link($yellow);
        }
    }

    &__title {
        margin: 0 0 0.4em;
        font-size: 4.2rem;
        font-weight: 700;
        font-family: $fancy-stack;

        > a {
            position: relative;
            color: inherit;
            text-decoration: none;

            &::after {
                content: '';
                position: absolute;
                z-index: -1;
                bottom: -0.1em;
                left: 0;
                width: 100%;
                height: 0.1em;
                background-color: $yellow;
                transform-origin: left center;
                transform: scaleX(0.2);
                transition: transform 200ms $standard;
            }

            &:hover::after {
                transform: scaleX(1);
            }
        }

    }
}

.home__section--secondary {
    display: flex;
    width: $max-width;
    max-width: 100%;
    margin: 0 auto 4rem;
    font-size: 0.9rem;

    &__column {
        width: 45%;
        margin: 0 5%;

        &.single {
            width: 50%;
            margin-top: 2em;

            @include media($on-mobile) {
                display: block !important;
                width: 100%;
            }
        }

        &:first-of-type {
            margin-left: 0;

            @include media($on-mobile) {
                display: none;
            }
        }

        @include media($on-tablet) {
            padding: 2em 2em 0;
        }

        @include media($on-mobile) {
            width: 100%;
            padding: 1em 1em 0;
            margin: 0;
        }

        &:last-of-type { margin-right: 0; }
    }

    &__meta {
        position: relative;
        padding-bottom: 2em;
        margin-bottom: 2em;

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 20%;
            height: 2px;
            background-color: $light-blue;
        }
    }

    &__title {
        font-size: 1.4rem;
        font-weight: 500;
    }

    &__author {
        font-size: 0.8rem;
    }

    &__digest-link {
        svg {
            width: 100%;
            height: 38px;
            max-width: 240px;
        }
    }

    &__text {
        line-height: 1.6;

        > a { @include link($light-blue); }
    }

    .links { margin-top: 2em; }

    .link { margin-left: 1em; }
}

.abstract-modal {
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    transition: all 250ms ease-in-out;

    &__close {
        position: absolute;
        top: 1em;
        right: 1em;

        svg { fill: $grey; }

        &:hover svg { fill: darken($grey, 10%); }
    }

    &__inner {
        position: relative;
        top: 50%;
        overflow-y: auto;
        box-sizing: border-box;
        padding: 1em;
        border-radius: 3px;
        margin: 0 auto;
        max-height: 80vh;
        max-width: 450px;
        background-color: $lightgrey;
        transform: translateY(-50%);

        h3 {
            margin-top: 0;
            font-size: 1.4rem;
            font-family: $fancy-stack;

            &::after {
                content: '';
                display: block;
                width: 100%;
                max-width: 50px;
                height: 2px;
                margin: 1em 0;
                background-color: $red;
            }
        }

        p {
            margin: 0;
            line-height: 1.6;
        }
    }

    &__overlay {
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: black;
        opacity: 0.5;
    }

    &.abstract-modal--open {
        opacity: 1;
        visibility: visible;
    }
}

