.digest-post-wrapper {
    display: flex;
    width: 100%;
    max-width: $max-width;
    margin: 0 auto;
}

.digest-post {
    margin-right: 2em;
    margin-bottom: 3em;

    @include media($on-mobile) {
        padding: 0 1em;
        margin-right: 0;
    }

    &__header {
        width: 100%;
        padding: 3em 0;

        @include media($on-mobile) {
            padding: 1em 0;
        }

        &__inner {
            width: 100%;
            max-width: 720px;
            margin: 0 auto;

            &::after {
                content: '';
                display: block;
                width: 100%;
                height: 1px;
                border-bottom: 1px dotted $grey;
                margin-top: 3em;
            }
        }
    }

    &__image {
        margin-bottom: 2em;

        img {
            position: relative;
            display: block;
            width: 100%;
            min-height: 100px;
        }

        img::before {
            content: '';
            position: absolute;
            top: -10px;
            left: 0;
            display: block;
            width: 100%;
            height: calc(100% + 15px);
            border: 2px dotted rgb(200, 200, 200);
            border-radius: 5px;
            background-color: rgb(230, 230, 230);
        }

        img::after {
            content: 'Broken Image - ' attr(alt);
            position: absolute;
            top: 50%;
            left: 50%;
            display: block;
            width: 80%;
            color: rgb(100, 100, 100);
            font-size: 0.8rem;
            text-align: center;
            transform: translate(-50%, -50%);
            white-space: pre-wrap;
        }
    }

    &__title {
        margin-top: 0;
        margin-bottom: 0.2em;
        font-family: $fancy-stack;
    }

    &__byline {
        margin-bottom: 0;
        font-size: 1.2rem;
    }

    &__postDate {
        font-size: 0.8rem;
    }

    &__body {
        width: 100%;
        max-width: 720px;
        margin: 0 auto;
        line-height: 1.6em;

        a { @include underline(blue, $yellow); }
    }

    &__footer {
        width: 100%;
        max-width: 720px;
        margin: 4em auto 0;

        &::before {
            content: '';
            display: block;
            width: 100%;
            height: 1px;
            border-bottom: 1px dotted $grey;
            margin: 3em 0;
        }

        &__related-title {
            font-family: $fancy-stack;

            &::after {
                content: '';
                display: block;
                width: 100%;
                max-width: 50px;
                height: 2px;
                margin: 1em 0;
                background-color: $red;
            }
        }

        &__related {
            border-collapse: separate;
            border-spacing: 0 1em;
            table-layout: fixed;

            td {
                padding-left: 1em;
                border: none;
                vertical-align: top;

                &:first-of-type {
                    padding-left: 0;
                }

                a {
                    &.image {
                        display: block;
                        height: 100px;
                        background-size: cover;
                        background-repeat: no-repeat;
                        background-position: 50% 50%;
                    }

                    &.title {
                        font-weight: 500;
                        font-family: $fancy-stack;

                        @include underline(black, $yellow);
                    }
                }

                &.no-image {
                    background-color: $grey;
                }
            }
        }
    }
}
