.wiki {
    &__aside {
        max-width: 200px;
        padding: 1em;
        font-size: 0.8rem;
        line-height: 1.6;

        ul, ol {
            box-sizing: border-box;
            padding-left: 0.8em;
            margin: 0;

            a { white-space: initial; }
        }

        ol {
            list-style-type: upper-roman;

            > li > a {
                font-weight: 700;
            }
        }

        ul { list-style-type: none; }

        @include media($on-mobile) {
            width: 100%;
            max-width: none;
            padding: 3em;
        }
    }

    &__embed {
        margin-right: -4rem;
        max-width: calc(100% + 4rem);

        &:first-of-type { margin-top: 4em; }

        & + & {
            &::before {
                content: '';
                display: block;
                width: 3em;
                height: 2px;
                margin: 2em auto;
                background-color: $red;
            }
        }

        @include media($on-mobile) {
            max-width: 100%;
            margin-right: 0;
        }
    }

    &__assets {
        margin-top: 2em;

        table {
            width: 100%;
            text-align: left;
        }
    }
}
