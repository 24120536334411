.header {
    width: 100%;
    padding: 2em 0;
    flex: none;
    background-image: url('../images/Circuit.png');
    background-size: 400px;
    background-repeat: repeat;
    background-color: $blue;

    @include media($on-mobile) {
        padding: 1em 0;
    }

    &--transparent {
        position: absolute;
        z-index: 998;
        top: 0;
        left: 0;
        background-image: none;
        background-color: transparent;
    }

    &--sticky {
        position: fixed;
        top: 0;
        left: 0;

        ~ .main { padding-top: 9em; }
    }

    &__inner {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include defWidth;

        @include media($on-tablet) {
            box-sizing: border-box;
            padding: 0 1em;
        }

        @include media($on-mobile) {
            display: block;
            padding: 0;
        }
    }

    &__user {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        color: white;

        a { margin-left: 1em; }

        @include media($on-mobile) {
            p { display: none; }

            a { margin: 0; }
        }

    }

    &__links {
        display: flex;
        align-items: center;

        @include media($on-tablet) {
            flex-wrap: wrap;

            &::before {
                content: '';
                width: 100%;
                order: 1;
            }
        }

        @include media($on-mobile) {
            justify-content: center;
        }
    }

    .jolt-logo {
        display: block;
        height: 50px;

        @include media($on-mobile) {
            height: 40px;
        }

        &--grey { fill: white; }

        &--teal { fill: $light-blue; }

        &--blue { fill: $light-blue; }

        &--yellow { fill: $yellow; }
    }

    &__digest-logo {
        display: block;
        width: 250px;

        svg {
            width: 100%;

            .digest-logo__jolt path { fill: white; }
        }

        @include media($on-tablet) {
            order: 2;
            margin-top: 1em;
            margin-left: 3.5em;
        }

        @include media($on-mobile) {
            display: none;
        }
    }

    &__button {
        float: right;
        padding: 0.6em 1em;
        border: 0;
        border-radius: 5px;
        background-color: mix(orange, $red, 50%);
        color: white;
        font-size: 0.8rem;
        font-family: $body-stack;
        text-transform: uppercase;
        text-decoration: none;
        transition: background-color 150ms;

        &:hover {
            background-color: darken(mix(orange, $red, 50%), 10%);
        }

        @include media($on-mobile) {
            float: none;
            font-size: 0.7rem;
        }
    }

    &__search {
        display: flex;
        margin-bottom: 0.5em;
        font-size: 0.8rem;

        @include media($on-mobile) {
            justify-content: center;
            margin: 0 0.5em 0 0;
            font-size: 0.7rem;
        }

        &__input {
            width: 20em;
            padding: 0.6em 1em;
            border: 0;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;

            @include media($on-mobile) {
                width: 18em;
            }
        }

        &__button {
            padding: 0.6em 1em;
            border: 0;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            margin: 0;
            background-color: mix(orange, $red, 50%);
            color: white;
            text-transform: uppercase;
            transition: background-color 150ms;

            svg {
                width: 1em;
                height: 1em;
                fill: white;
            }

            &:hover {
                background-color: darken(mix(orange, $red, 50%), 10%);
            }
        }
    }

    &__extra {
        @include media($on-mobile) {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 1em;
        }
    }
}
