.volume {
    padding-left: 2em;
    font-size: 0.75rem;

    @include media($on-mobile) {
        padding-left: 0;
    }

    &:last-of-type {
        padding-bottom: 6em;
    }

    &-wrapper {
        display: flex;
        box-sizing: border-box;
        width: 100%;
        max-width: $max-width;
        margin: 0 auto;
        background-color: white;

        @include media($on-mobile) {
            display: block;
            padding: 2em;
        }

        .aside { margin-left: auto; }
    }

    &__volumes {
        box-sizing: border-box;
        padding: 0 6em;
        margin: 3em 0 0;

        @include media($on-mobile) {
            padding: 0;
            margin-top: 0;
            margin-right: 0;
        }
    }

    & + & {
        &::before {
            content: '';
            display: block;
            width: 100%;
            height: 1px;
            border-bottom: 1px dotted $grey;
            margin: 3em 0;
        }
    }

    > h2 {
        margin-top: 0;
        color: $blue;
        font-family: $fancy-stack;
    }

    &__volume {
        line-height: 1.6;

        li:not(:first-of-type) { margin-top: 0.4em; }

        a {
            position: relative;
            border-bottom: 2px dotted $yellow;
            text-decoration: none;

            &:hover {
                border-color: $blue;
            }
        }

        @include media($on-mobile) {
            display: block;
        }
    }


    &__section {
        margin-top: 2em;

        > h3 { margin-top: 0; }

        @include media($on-mobile) {
            &:first-child {
                margin-right: 0;
            }
        }
    }

    &__notes > ul, &__articles > ul {
        width: 100%;
        max-width: 460px;
        padding: 0 0 0 1em;
        margin: 0;
    }
}
