// Global Variables
// ----------------------
// 1. Colors
// 2. Values
// 3. Animations


// Colors
// ---------------
$grey:		#969696;
$lightgrey:	lighten($grey, 35%);
$teal:		#0f7693;
$blue:		#191970;
$light-blue: #43b0f2;
$dark-blue: mix($blue, black, 40%);
$yellow:	#ffcc00;
$red:	    #751c18;
$soft-blue: #5d5d86;


// Values
// ---------------
$max-width: 970px;
$spacing:	32px;
$nav-width: 300px;


// Fonts
// ---------------
$title-family: 'Source Sans Pro';
$title-stack:  $title-family, Helvetica, Arial, sans-serif;

$body-family: 'Roboto';
$body-stack:  $body-family, Helvetica, Arial, sans-serif;

$fancy-family: 'Source Serif Pro';
$fancy-stack:  $fancy-family, Georgia, serif;

// Animations
// ---------------
$standard: cubic-bezier(0.4, 0, 0.2, 1);
$enter: cubic-bezier(0, 0, 0.2, 1);
$exit: cubic-bezier(0.4, 0, 1, 1);
$bounce: cubic-bezier(0.175, 0.885, 0.320, 1.275);
$bounce-less: cubic-bezier(0.12, 0.85, 0.3, 1.1);
