.global-nav {
    position: fixed;
    z-index: 999;
    top: 0;
    left: -$nav-width;
    width: $nav-width;
    height: 100%;
    background-color: $dark-blue;
    color: white;

    transition: transform 200ms;
    transition-timing-function: $exit;

    &.nav--open {
        transform: translateX(100%);
        transition-timing-function: $enter;

        .nav__close {
            opacity: 1;
            visibility: visible;
        }
    }
}

.nav {
    &__close {
        position: absolute;
        top: 1em;
        right: -2em;
        opacity: 0;
        visibility: hidden;
    }

    &__logo {
        display: block;
        box-sizing: border-box;
        padding: 0 2em;
        margin: 3em 0 2em;

        svg {
            fill: $lightgrey;
            width: 100%;
            height: 100%;
        }
    }

    &__list {
        padding: 0;
        margin: 0;
        list-style-type: none;

        &-item {
            &:not(:last-of-type) {
                border-bottom: 1px solid darken($soft-blue, 20%);
            }
        }
    }

    &__link {
        position: relative;
        display: block;
        box-sizing: border-box;
        width: 100%;
        padding: 1em 2em;
        color: $lightgrey;
        font-family: $fancy-stack;
        text-decoration: none;
        transition: transform 150ms $standard;

        &::before {
            content: '';
            position: absolute;
            top: calc(50% - 2px);
            left: 0;
            width: 1em;
            height: 4px;
            background-color: $yellow;
            transform: translateX(0) scaleX(0);
            transition: transform 150ms $standard;
        }

        &:hover, &.is-active {
            transform: translateX(1em);

            &::before {
                transform: translateX(-0.2em) scaleX(1);
            }
        }
    }
}

.nav-button {
    position: relative;
    width: 28px;
    height: 30px;
    padding: 0;
    border: none;
    margin-right: $spacing;
    background: transparent;
    outline: none;

    &:focus {
        outline: initial;
    }

    span {
        display: block;
        width: 100%;
        height: 2px;
        background-color: white;

        &::after, &::before {
            content: '';
            position: absolute;
            left: 0;
            display: block;
            width: 100%;
            height: 2px;
            background-color: white;
            transition: transform 0.25s ease-in-out;
        }

        &::after { top: 4px; }

        &::before { bottom: 4px; }
    }

    &:hover, &:focus {
        span::after { transform: translateY(-4px); }

        span::before { transform: translateY(4px); }
    }
}

.overlay {
    position: fixed;
    z-index: 998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0;
    visibility: hidden;
    transition: opacity 200ms, visibility 200ms;
    transition-timing-function: $standard;
    transition-delay: 0ms;

    &.is-visible {
        opacity: 0.5;
        visibility: visible;
    }
}
