html { height: 100%; }

body {
    display: flex;
    height: 100%;
    flex-direction: column;
    font-family: $body-stack;

    &.body--no-scroll {
        overflow-y: hidden;
    }

    &.body--blue {
        background-color: $dark-blue;
    }

    &.body--grey {
        background-color: $lightgrey;
    }

    &.is-ie {
        .home__section--featured {
            padding-bottom: 100px;
            margin-bottom: 50px;
            background-color: $blue;
            background-image: url('../images/Circuit.png');
            background-size: 400px;

            .angle { display: none; }
        }
    }
}

.main {
    flex: 1 0 auto;
}

.accent-title {
    color: $grey;
    font-family: $fancy-stack;
    font-size: 1.2rem;
}

.accent-type {
    color: $blue;
    font-family: $body-stack;
    font-size: 0.8rem;
    text-transform: uppercase;
    letter-spacing: 0.2em;
}

%card {
    box-shadow: 0 2px 4px rgba(black, 0.1);
    border-radius: 3px;
    background-color: white;
}

.form__option {
    &--2 {
        margin-left: 16px;
    }
}

.btn {
    display: inline-block;
    padding: 0.6em 1em;
    border: 0;
    border-radius: 5px;
    background-color: $red;
    color: white;
    font-size: 0.8rem;
    font-family: $body-stack;
    text-transform: uppercase;
    text-decoration: none;
    transition: background-color 150ms;

    &:hover {
        background-color: darken($red, 10%);
    }

    &--blue {
        background-color: $blue;

        &:hover {
            background-color: darken($blue, 10%);
        }
    }
}

.link {
    @include link($blue);
}

.link--red {
    @include link($red);
}

.btn--close {
    display: block;
    width: 1em;
    height: 1em;
    padding: 0;
    border: none;
    background: none;

    svg {
        width: 100%;
        height: 100%;
        transition: all 150ms $standard;
        fill: $lightgrey;
    }

    &:hover svg { fill: white; }
}

.aside {
    max-width: 300px;
    padding: 1em;
    background-color: $lightgrey;

    @include media($on-mobile) {
        box-sizing: border-box;
        width: 100%;
        max-width: none;
        padding: 1em;
    }

    &__title {
        margin-top: 0;
        color: $grey;
        font-family: $fancy-stack;
    }

    &__list {
        list-style-type: none;
        padding: 0;
        margin: 0;

        &-item {
            margin-top: 0.4em;

            &:first-of-type { margin-top: 0; }

            &__link {
                border-bottom: 1px dotted rgba($blue, 0.2);
                color: $blue;
                white-space: pre;
                text-decoration: none;

                &:hover {
                    border-bottom-color: $blue;
                }
            }
        }
    }

    nav + nav {
        margin-top: 2em;
    }
}

.pullQuote {
    float: right;
    max-width: 300px;
    padding: 1em;
    border: 2px solid $yellow;
    margin: 0 0.8em 0.8em;

    p, ul { margin-top: 0; }

    ul { margin-bottom: 0; }
}
