.symposium, .wiki {
    &-wrapper {
        display: flex;
        width: 100%;
        max-width: $max-width;
        justify-content: space-between;
        margin: 0 auto;

        @include media($on-mobile) {
            display: block;
        }
    }

    &__article {
        margin-right: 6em;
        margin-bottom: 3em;
        font-size: 0.9rem;
        max-width: 600px;

        ul {
            padding: 1em;
        }

        p { line-height: 1.6; }

        a { @include underline($blue, $yellow); }

        @include media($on-tablet) {
            padding: 0 1em;
        }

        @include media($on-mobile) {
            margin-right: 0;
        }
    }

    &__title {
        color: $grey;
        font-family: $fancy-stack;

        &::after {
            content: '';
            display: block;
            width: 100%;
            max-width: 50px;
            height: 2px;
            margin: 1em 0;
            background-color: $red;
        }
    }
}
