.login {
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    flex-direction: column;
    transform: translate(-50%, -50%);

    @include media($on-mobile) {
        position: static;
        box-sizing: border-box;
        width: 100%;
        padding: 1em;
        transform: none;
    }

    &__logo {
        display: inline-block;
        width: 80%;
        max-width: 360px;
        margin: 0 auto 2em;

        .jolt-logo {
            width: 100%;
            fill: white;
        }
    }


    &__forms {
        display: flex;
        overflow: hidden;
        border-radius: 3px;
        background-color: $lightgrey;

        @include media($on-mobile) {
            display: block;
            overflow: initial;
            margin-bottom: 1em;
        }
    }

    &__login {
        border-radius: 3px;
    }

    &__login, &__signup {
        padding: 1em 1em 4em;
    }

    &__reset {
        padding: 1em;

        button[type='submit'] {
            margin-top: 2em;
        }
    }

    &__login, &__signup, &__reset {
        form {
            display: flex;
            height: 100%;
            flex-direction: column;
        }

        h2 {
            font-family: $fancy-stack;
        }

        .btn {
            display: block;
            margin-top: auto;

            @include media($on-mobile) {
                margin-top: 1em;
            }
        }

        h3 {
            margin-bottom: 0;
            color: $grey;
            font-size: 0.8rem;
            font-weight: 500;
        }

        input {
            padding: 0.4em;
            border-radius: 3px;
            border: 1px $grey solid;
        }

        .remember-me {
            display: flex;
            align-items: center;
            margin: 0.6em 0;
            font-size: 0.8rem;

            input { margin-right: 0.4em; }
        }

        p {
            font-size: 0.8rem;

            a { @include underline($blue, $yellow); }
        }
    }

    &__login, &__reset {
        background-color: white;
    }

    &__signup {
        position: relative;

        &__errMsg {
            position: absolute;
            top: 0;
            left: 0;
            box-sizing: border-box;
            padding: 1em;
            margin: 0;
            background-color: $red;
            color: white !important;
        }
    }
}
