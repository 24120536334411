.speakers {
    box-sizing: border-box;
    width: 100%;
    max-width: $max-width;
    padding: 2em 6em 4em;
    margin: 0 auto;
    background-color: white;

    a {
        @include underline(blue, $yellow);
    }

    @include media($on-mobile) {
        padding: 1em;
    }

    &__title {
        font-family: $fancy-stack;

        &::after {
            content: '';
            display: block;
            width: 100%;
            max-width: 50px;
            height: 2px;
            margin: 1em 0;
            background-color: $red;
        }
    }

    &__header {
        margin-bottom: 4em;
    }

    &__table {
        border-collapse: collapse;
        font-size: 0.9rem;
    }

    &__row:not(:last-child) {
        border-bottom: 1px solid $grey;
    }

    &__data {
        padding: 1em 2em;

        &:first-of-type {
            padding-left: 0;
        }

        &:nth-child(2) {
            @include media($on-mobile) {
                display: none;
            }
        }

        &:last-of-type {
            padding-right: 0;
        }

        &--date {
            white-space: pre;
        }
    }

    &__more-info {
        &-btn {
            display: block;
            white-space: pre;
        }

        display: none;

        &.is-active {
            display: block;
        }
    }
}

.speakers-modal {
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;

    transition: all 250ms ease-in-out;

    &__inner {
        position: relative;
        top: 50%;
        overflow: auto;
        box-sizing: border-box;
        width: 90%;
        padding: 4em;
        border-radius: 3px;
        margin: 0 auto;
        max-width: 720px;
        background-color: $lightgrey;
        transform: translateY(-50%);
        max-height: 90%;

        @include media($on-mobile) {
            padding: 1.5em;
        }

        *:first-child { margin-top: 0; }
    }

    &__close {
        position: absolute;
        top: -2em;
        right: 0;
    }

    &__overlay {
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: black;
        opacity: 0.5;
    }

    &.speakers-modal--open {
        opacity: 1;
        visibility: visible;
    }
}
